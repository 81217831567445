import firebase from 'firebase'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: () => import('@/views/Home.vue'),
  //   meta: {
  //     layout: 'content',
  //     requiresAuth: true,
  //   },
  // },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
    },
  },
  {
    path: '/invoices/create-invoice',
    name: 'create-invoice',
    component: () => import('@/views/invoices/CreateInvoice.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
    },
  },
  // {
  //   path: '/invoices/upload-invoice',
  //   name: 'upload-invoice',
  //   component: () => import('@/views/invoices/UploadInvoice.vue'),
  //   meta: {
  //     layout: 'content',
  //     requiresAuth: true,
  //   },
  // },
  {
    path: '/reports/contractor-balance-sheet',
    name: 'contractor-balance-sheet',
    component: () => import('@/views/reports/ContractorBalanceSheet.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
    },
  },
  {
    path: '/reports/submitted-invoices',
    name: 'submitted-invoices',
    component: () => import('@/views/reports/SubmittedInvoices.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
    },
  },
  {
    path: '/settings/settings',
    name: 'settings',
    component: () => import('@/views/settings/Settings.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
    },
  },
  {
    path: '/chooseOrg',
    name: 'chooseOrg',
    component: () => import('@/views/ChooseOrg.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
    requiresAuth: true,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const currentUser = firebase.auth().currentUser
    if (currentUser) {
      let selectedOrg = sessionStorage.selectedOrg
      if (!selectedOrg && to.name !== 'chooseOrg') {
        next({
          path: '/chooseOrg',
        })
      } else {
        next()
      }
    } else {
      alert('You must be logged in to see this page')
      next({
        path: '/login',
      })
    }
  } else {
    next()
  }
})

export default router
